












import Vue from 'vue'
import CustomerIssueForm from '../components/CustomerIssueForm.vue'
import ICustomerIssue from '../models/ICustomerIssue'

export default Vue.extend({
  components: {
    CustomerIssueForm
  },
  props: {
    value: Object as () => ICustomerIssue
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
  },
  methods: {
    async onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
