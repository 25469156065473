



















import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import ICustomerLicenseModel from '../models/ICustomerLicenseModel'

export default Vue.extend({
  props: {
    value: Array as () => ICustomerLicenseModel[],
    productId: Number
  },
  data() {
    return {
      sortBy: ['licenseCount'],
      sortDesc: [true]
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Customer',
          value: 'customerName'
        },
        {
          text: 'Organization',
          value: 'organizationName'
        },
        {
          text: 'Licenses',
          value: 'licenseCount'
        }
      ]
      return headers
    }
  },
  methods: {
    productLicenseInfo() {
      const licenses = this.value.filter(x => x.productId === this.productId)
      return licenses
    },
    getCount(count: number) {
      if (count === 0) {
        return 'Unknown'
      }
      return count
    }
  },
  watch: {
  }
})
