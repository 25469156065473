




















































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'

const { mapGetters, mapActions } = createNamespacedHelpers('Customers')

export default Vue.extend({
  props: {
    error: Error
  },
  components: {
  },
  data() {
    return {
      loading: false,
      searchBy: 'TPID',
      search: '',
      select: null,
      items: [],
      errorMessages: null
    }
  },
  computed: {
    ...mapGetters(['hasFavorites', 'hasAssignments']),
    canSearch() : Boolean {
      return this.search !== null && this.search.trim() !== ''
    }
  },
  methods: {
    ...mapActions({
      suggest: 'suggest'
    }),
    async querySelections(searchText: string) {
      this.loading = true
      try {
        var response = await this.suggest({
          searchText
        })
        if (response) {
          this.items = response.map(x => {
            return {
              value: x.key,
              text: `(${x.key}) ${x.name}`,
              customerId: x.customerId,
              isOrganization: x.isOrganization,
              id: x.id
            }
          })
        } else {
          this.items = []
        }
      } finally {
        this.loading = false
      }
    },
    onShowFavorites() {
      this.$emit('search', {
        searchText: '',
        customerId: 0,
        favorites: true,
        directAssignments: false,
        $_isOrganization: false,
        $_id: 0
      })
    },
    onShowDirectAssignments() {
      this.$emit('search', {
        searchText: '',
        customerId: 0,
        favorites: false,
        directAssignments: true,
        $_isOrganization: false,
        $_id: 0
      })
    },
    onEnter() {
      this.$emit('search', {
        searchText: this.search,
        customerId: 0,
        favorites: false,
        directAssignments: false,
        $_isOrganization: false,
        $_id: 0
      })
    }
  },
  watch: {
    search: debounce(function (this: any) {
      this.errorMessages = ''
      if (this.search === null || this.search.trim() === '') {
        this.items = []
      }
      this.search && this.search !== this.select && this.querySelections(this.search)
    }, 100),
    select(val) {
      const searchResult = this.items.filter(x => x.value === val)[0]
      this.$emit('search', {
        searchText: '',
        customerId: searchResult.customerId,
        favorites: false,
        directAssignments: false,
        $_isOrganization: searchResult.isOrganization,
        $_id: searchResult.id
      })
    },
    error(val) {
      if (val.message === 'Request failed with status code 404') {
        this.errorMessages = 'Customer not found'
      } else {
        this.errorMessages = val.message
      }
    }
  }
})
